// import React from 'react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'react-feather';
import { Instagram, Send } from 'react-feather';
import StarR from './starr.jpg';
import { Dropdown,DropdownItem,DropdownToggle,DropdownMenu } from 'reactstrap';

function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  const handleLinkClick = () => {
      setIsOpen(false);
    }

const renderMenu = () => {
  if (isMobile) {
    return (
      <div className="mobile-menu">
        <button className="menu-toggle" onClick={toggleMenu}><Menu /></button>
        <div className={`menu-items ${isOpen ? 'open' : ''}`}>
          <Link to="/" onClick={handleLinkClick}>الرئيسية</Link>
          {/* <Link to="/" onClick={handleLinkClick}>حب الروح</Link> */}
          {/* <Link to="/pricing" onClick={handleLinkClick}>الأسعار</Link>
          <Link to="/usage" onClick={handleLinkClick}>عن نها</Link>
          <Link to="/terms" onClick={handleLinkClick}>شروط الإستخدام</Link>
          <Link to="/privacy" onClick={handleLinkClick}>Privacy</Link> */}
          {/* <div className="language-menu">
          <a href="#">اللغة</a>
            <ul>
              <li><a href="#">English</a></li>
              <li><a href="#">العربية</a></li>
            </ul>
          </div>
          <li className="signin"><a href="#">تسجيل الدخول</a></li> */}
        </div>
      </div>
    );
  } else {
    return (
      <nav id='menu'>
        <ul>
          <li><a href='/'>الرئيسية</a></li>
          {/* <li><a href='#'>حب الروح</a></li> */}
          <li className="soc"><a className="mIcon" href='https://www.instagram.com/nohakammoun369/' target='_blank'><Instagram /><p>إنستغرام</p></a></li>
          <li className="soc"><a className="mIcon" href='https://t.me/noha_kammoun'target='_blank'><Send /><p>تيليغرام</p></a></li>                  
          {/* <li class="language-menu">
            <a href="#">اللغة<span class="caret"></span></a>
            <ul className='sub_menu'>
              <li className='sub_menu_li'><a href="#">English</a></li>
              <li className='sub_menu_li'><a href="#">العربية</a></li>
            </ul>
          </li>
          <li className="signin"><button>تسجيل الدخول</button></li> */}
          {/* <li className="signin"><a href="#">تسجيل الدخول</a></li> */}
        </ul>
      </nav>
    );
  }
};
  return (
    <header>
    <div className='h_top'>
    {/* image */}
    <img src={StarR} className='star_l'></img>
    <div className='h_text'>    
      <h2 className='s_n'>نها كمون</h2>
      <h2 className='s_n'>Noha Kammoun</h2>
    </div>
    <img src={StarR} className='star_r'></img>
    </div>

    <div className="centerMenu">
      {renderMenu()}
    </div>
    </header>
  );
};

export default Header;
import React, { Component } from 'react';
import { Row, Col, CardBody, Card, Button } from 'reactstrap';
import Nana from './images/Nana.jpg';
import * as Feather from 'react-feather';
// const feather = require('feather-icons')
// import { Send } from 'react-feather';
// import { CreditCard, X, Check} from 'react-feather';
import { Instagram, Send } from 'react-feather';
import lite from './images/1.jpg';
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false, 
        }
    }
    render() {
        return (
        <React.Fragment>
            <div className='div1'>
                <div className='div1-r'>
                     <img src={Nana} className='Nana'></img>
                </div>
                <div className='div1-l'>
                    <p>مدربة في حب الروح والتصالح مع الذات والحياة</p>
                    <p>سفيرة الأكاديمية الدولية د. صلاح الراشد</p>
                    <p>Heal Your Life ®  Workshop Leader & Life Coach</p>
                    <p>ICF Member</p>
                </div>
            </div>
            <div className='social'>
            <button className='social_b' onClick={() => window.open('https://www.instagram.com/nohakammoun369/', '_blank')}>Instagram<Instagram /><span>إنستغرام</span></button>
            <button className='social_b' onClick={() => window.open('https://t.me/noha_kammoun', '_blank')}>Telegram<Send /><span>تيليغرام</span></button>
            </div>

            <div className='div2'>
                <div className='div2-1'>
                    <h2>تعلم</h2>
                    <p>تعلم أكثر عن نفسك ومحيطك .تعلم أكثر عن الأشياء التي انت تحبها وتثير إنتباهك.
                    تعلم العلم الذي يساعدك على إدراك مكامن قوتك وتعلم كيف تحسن من نقاط الضعف التي لديك .
                    تعلم كيف تحب نفسك أكثر.
                    تعلم كيف تكون واعي بأفكارك وكلماتك ونفسك.
                    تعلم كيف تتجاوز مخاوفك  لتكون إنسان واثق شجاع.
                    </p>           
                </div>

                <div className='div2-1'>
                   <h2>إكتشف</h2>
                    <p>إكتشف قدراتك الداخلية وإكتشف ما أنت قادر عليه
                    إكتشف نفسك وإكتشف اسباب وجودك هنا
                    إكتشف حياتك وما هو الذي يحركك
                    إكتشف ما هي الأشياء التي تسعدك
                    إكتشف ما الذي أنت بارع فيه وتحبه
                    إكتشف الأصدقاء الحقيقين
                    إكتشف  قوتك وتألق
                    إكتشف كنوزك فتنجح
                    </p>           
                </div>

                <div className='div2-1'>
                   <h2>تشافى</h2>
                    <p>
                    تشافى وتعافى من ألمك ومصابك, خسارتك, أحزانك, أوقف معاناتك تستطيع تعرف على الطريقة ,تعرف على الذي تجهله, خذ خطوة تجاه تحسين أفكارك مشاعرك سلوكياتك تصرفاتك فتتغير حياتك 
                    تشافى تعافى فتسعد
                    </p>  
                </div>

            </div>         
        </React.Fragment>
        );
    }
}
export default Home;
import logo from './logo.svg';
// import './App.css';
import './style.css';
import Header from './Header';
import Footer from './Footer';
import Home from './pages/home';


import { Container } from '@mui/material';
import { BrowserRouter as Router, Switch, Route, Routes, Link } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Header />
      <main className='App-main'>
      <Container className='centerMain'>
      <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Container>
      </main>
      <Footer />
    </div>

  );
}

export default App;

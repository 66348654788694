// import React from 'react';

// function Footer() {
//   return (
//     <footer className='footer'>
//       <div>👸 ❤️</div>
//       <div>© 2023 Noha Kammoun</div>
//       <div><a href="https://t.me/Noha_support_bot" target="blank" >Support</a> | <a onclick={openModal()}>Cookies</a></div>
//     </footer>
//   );
// }

// export default Footer;


import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function Footer() {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <footer className='footer'>
      <div>💗</div>
      <div>نها كمون. جميع الحقوق محفوظة ©</div>
      <div>© 2023 Noha Kammoun</div>
    </footer>
  );
}
export default Footer;